<template>
  <layout-Single>
    <router-view />
  </layout-Single>
</template>

<script>
import LayoutSingle from '@core/layouts/layout-full/LayoutFull.vue'

export default {
  components: {
    LayoutSingle,

  },
}
</script>
